<template>
    <ul class="tab_sec mb-4">
        <li @click="form.type = 'url'" :class="{ active: form.type === 'url' }">URL</li>
        <li @click="form.type = 'embed'" :class="{ active: form.type === 'embed' }">Embed</li>
        <li @click="form.type = 'image'" :class="{ active: form.type === 'image' }">Optional Image</li>
    </ul>
    <div class="setting_wpr">
        <div v-show="form.type === 'url'">
            <div class="form_grp">
                <div class="group_item">
                    <div class="field_wpr has_prefix" :class="{ 'has-error': errors[`url_${fieldKey}`] }">
                        <Field autocomplete="off" type="text" :name="`url_${fieldKey}`" v-model="form.url" placeholder="Video Link Goes Here..." rules="url" label="url" />
                        <span class="prefix">URL</span>
                    </div>
                    <!-- <small class="url-info">Add https:// or http:// prefix eg https://yourdomain.com</small> -->
                    <ErrorMessage :name="`url_${fieldKey}`" class="text-danger" />
                </div>
            </div>
        </div>
        <div v-show="form.type === 'embed'">
            <div class="form_grp">
                <div class="group_item">
                    <div class="field_wpr" :class="{ 'has-error': errors[`embed_${fieldKey}`] }">
                        <Field autocomplete="off" type="textarea" :name="`embed_${fieldKey}`" v-model="form.embed" label="embed">
                            <textarea cols="10" rows="10" placeholder="Embed Code Here.." v-model="form.embed"></textarea>
                        </Field>
                    </div>
                    <ErrorMessage :name="`embed_${fieldKey}`" class="text-danger" />
                </div>
            </div>
        </div>
        <div v-show="form.type === 'image'">
            <image-library v-model="form.image" image-type="playbook-step-thumb" />
        </div>
        <div class="form_grp">
            <div class="group_item">
                <label class="input_label">{{ title }}</label>
                <div class="field_wpr" :class="{ 'has-error': errors[`title_${fieldKey}`] }">
                    <Field autocomplete="off" type="text" :name="`title_${fieldKey}`" v-model="form.title" placeholder="ex: Title Text" />
                </div>
                <ErrorMessage :name="`title_${fieldKey}`" class="text-danger" />
            </div>
        </div>
    </div>
</template>

<script>
    import ImageLibrary from '@/components/image-library/ImageLibrary'

    import { Field, ErrorMessage } from 'vee-validate'

    export default {
        name: 'Content Thumbnail',

        data () {
            return {
                form: {
                    type: 'url',
                    url: '',
                    embed: '',
                    image: '',
                    title: '',
                },
                loaded: false,
                fieldKey: new Date().getUTCMilliseconds(),
            };
        },

        props: {
            modelValue: Object,
            errors: Object,
            isDynamic: {
                type: Boolean,
                default: false,
            },
            reload: {
                type: Boolean,
                default: false,
            },
            tab: String,
            title: {
                type: String,
                default: 'Title Text'
            }
        },

        emits: ['update:modelValue'],

        components: {
            ImageLibrary,
            ErrorMessage,
            Field,
        },

        watch: {
            modelValue (modelValue, old) {
                const vm = this;

                if (modelValue != old && vm.isDynamic) {
                    vm.loaded = false;
                }

                if (!vm.loaded) {
                    vm.form   = modelValue ? JSON.parse(JSON.stringify(modelValue)) : {};

                    setTimeout(function () {
                        vm.loaded = true;
                    }, 10);
                }
            },

            form: {
                handler (form) {
                    const vm = this;

                    if (vm.loaded) {
                        vm.$emit('update:modelValue', form);
                    }
                },
                deep: true,
            },

            reload (reload) {
                if (reload) {
                    const vm = this;

                    vm.form = JSON.parse(JSON.stringify(vm.modelValue));
                }
            },

            tab (tab) {
                if (tab == 'main') {
                    const vm = this;

                    // vm.fieldKey = new Date().getUTCMilliseconds();

                    if (vm.modelValue) {
                        vm.form = JSON.parse(JSON.stringify(vm.modelValue));
                    }
                }
            }
        },

        mounted () {
            const vm = this;

            setTimeout(function () {
                vm.loaded = true;
            }, 1000);
        },
    }
</script>

<style scoped>
</style>
